import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import { CircularProgress } from "@mui/material";
import { Helmet } from 'react-helmet';
import "./contact.css";
import { useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_CONTACTUS;

  const handleSubmit = async () => {
    if (
      formData.firstName &&
      formData.lastName &&
      formData.phone &&
      formData.email &&
      formData.message
    ) {
      setLoading(true);

      try {
        const response = await axios.post(`https:${api}`, formData, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        console.log(response.data)

        if (response.data.message === "Email sent successfully.") {
          toast.success("Successfully Send your message ");
          toast.success("Our team contact you soon....");
        }

        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          message: "",
        });
      } catch (error) {
        console.error("Error posting data:", error);
        toast.error("Failed to send the message. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please fill in all fields");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - RCI Chikankari</title>
        <meta name="description" content="Get in touch with RCI Chikankari for inquiries, customer support, or any questions regarding our handmade Chikankari kurtis. We're here to help!" />
        <meta name="keywords" content="Contact RCI Chikankari, customer support, Chikankari kurtis inquiries, contact for handmade kurtis, women kurtis help" />
        <meta property="og:title" content="Contact Us - RCI Chikankari" />
        <meta property="og:description" content="Need assistance with your order or have inquiries about our products? Contact RCI Chikankari for customer support." />
        <meta property="og:url" content="https://rcichikankari.com/contact" />
        <link rel="canonical" href="https://rcichikankari.com/contact" />
      </Helmet>
      <div className="productpage_head_container">
        <div className="productpage_head">
          <p>Contact</p>
        </div>
        <div className="productpage_subhead">
          <p>Home / contact</p>
        </div>
      </div>
      <div className="contactpage_container_form">
        <div className="contactpage_container_details">
          <div className="contactpage_conatiner_details_box">
            <div className="contactpage_conatiner_details_head">
              <p>Here to Help</p>
            </div>
            <div className="contactpage_conatiner_details_para">
              <p>
                Have a question? You may find an answer in our FAQs. But you can
                also contact us:
              </p>
            </div>
          </div>
          <div className="contactpage_conatiner_details_box">
            <div className="contactpage_conatiner_details_head">
              <p>RCI - India</p>
            </div>
            <div className="contactpage_conatiner_details_para">
              <div>
                <p>Rupesh chikan industries</p>
                <p>325/81, sondhi tola, chowk lko</p>
                <p>Landmark: SS PHARMA</p>
                <p>pincode: 226003</p>
              </div>
              <div>
                <p>support@rcichikankari.com</p>
              </div>
              <div>
                <p>9305299500 or 9839122700</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contactpage_container_contactform_form">
          <div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "47%" },
              }}
              className="box_wholesale_width"
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Box>
          </div>
          <div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "47%" },
              }}
              className="box_wholesale_width"
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Box>
          </div>
          <div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "96%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Message"
                variant="outlined"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Box>
          </div>
          <div className="wholesalepage_button">
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                endIcon={
                  loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <SendIcon />
                  )
                }
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </Button>
            </Stack>
          </div>
        </div>
      </div>
      <div className="map_contactpage">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d227791.57815258505!2d80.93152642187499!3d26.86400663161416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1710178025460!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
