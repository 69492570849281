import { useEffect } from 'react';
import '../size/sizechart.css'
// import Box from '@mui/material/Box';
import { IoMdClose } from "react-icons/io";
import p1 from './c (1).jpg';
import p2 from './c (2).jpg';
import p3 from './c (3).jpg';
import p4 from './c (4).jpg';
import p5 from './c (5).jpg';
import p6 from './c (6).jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore from 'swiper/core';
import { Navigation } from 'swiper/modules';

SwiperCore.use([Navigation]);

// import TextField from '@mui/material/TextField';
const Color = ({ closeViewSize }) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'; // overflow hidden lagane ke liye
        return () => {
            document.body.style.overflow = 'auto'; // Component unmount hone par overflow wapas se set karein
        };
    }, []);
    return (
        <>
            <div className='view_parent'></div>

            <div className='search_area_container'>
                <div className='search_area_sub_container'>
                <div onClick={closeViewSize} className='search_close_button'><IoMdClose /></div>
                    <Swiper navigation={true} className="mySwiper">
                        <SwiperSlide className="myimageSwiper">
                            <div className='size_container'>
                                <div className='size_container_chart'>
                                    <img src={p4} alt="" width='100%' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="myimageSwiper">
                            <div className='size_container'>
                                <div className='size_container_chart'>
                                    <img src={p5} alt="" width='100%' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="myimageSwiper">
                            <div className='size_container'>
                                <div className='size_container_chart'>
                                    <img src={p6} alt="" width='100%' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="myimageSwiper">
                            <div className='size_container'>
                                <div className='size_container_chart'>
                                    <img src={p1} alt="" width='100%' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="myimageSwiper">
                            <div className='size_container'>
                                <div className='size_container_chart'>
                                    <img src={p2} alt="" width='100%' />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="myimageSwiper">
                            <div className='size_container'>
                                <div className='size_container_chart'>
                                    <img src={p3} alt="" width='100%' />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                  
                </div>
            </div>

        </>
    )
}

export default Color