import './terms.css';
import { Helmet } from 'react-helmet';
const Policy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy - RCI Chikankari</title>
                <meta name="description" content="Learn about how RCI Chikankari collects, uses, and protects your personal information. Our Privacy Policy explains our practices in detail to ensure your privacy is respected." />
                <meta name="keywords" content="RCI Chikankari privacy policy, data protection, personal information, user privacy, GDPR compliance, privacy policy for e-commerce" />
                <meta property="og:title" content="Privacy Policy - RCI Chikankari" />
                <meta property="og:description" content="At RCI Chikankari, we respect your privacy and protect your personal information. Read our Privacy Policy to learn how we handle your data." />
                <meta property="og:url" content="https://rcichikankari.com/policy" />
                <link rel="canonical" href="https://rcichikankari.com/policy" />
            </Helmet>
            <div className='productpage_head_container'>
                <div className='productpage_head'>
                    <p>Privacy Policy</p>
                </div>
                <div className='productpage_subhead'>
                    <p>Home / Policy</p>
                </div>
            </div>
            <div className='policypage'>
                <div className='policypage_container'>
                    <div className='policypage_para'>
                        <p>At RCI, we are committed to protecting your privacy. This privacy policy outlines how we collect, use, and safeguard your personal information.</p>
                    </div>
                    <div className='policypage_listorder'>
                        <ol>
                            <li>
                                <div className='policypage_listorder_head'><p>Information Collection:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We may collect personal information such as your name, email address, shipping address, and payment details when you place an order or sign up for our newsletter.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Information Usage: </p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We use your personal information to process orders, communicate with you, provide customer support, and improve our services.</li>
                                        <li>We may also use your information to send promotional offers or newsletters if you have subscribed to them.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Information Sharing: </p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We do not sell, trade, or rent your personal information to third parties without your consent, except as required by law or as necessary to fulfill your order.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Data Security:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Cookies:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Our website may use cookies to enhance your browsing experience. You have the option to disable cookies in your web browser, but this may affect the functionality of our website.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>External Links:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Our website may contain links to external sites. We are not responsible for the privacy practices or content of these external sites.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Updates to the Privacy Policy:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We reserve the right to update or modify our privacy policy at any time. Any changes will be posted on this page.</li>
                                    </ul>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>By using our website, you consent to the collection and use of your personal information as described in this privacy policy.</p>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>If you have any questions or concerns regarding our privacy policy, please contact us.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Policy