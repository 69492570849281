import React from 'react'
import './terms.css';
import { Helmet } from 'react-helmet';
const Howtoorder = () => {
    return (
        <>
            <Helmet>
                <title>How to Order - RCI Chikankari</title>
                <meta name="description" content="Learn how to place an order on RCI Chikankari. Follow our step-by-step guide to browse, select, and purchase our handmade Chikankari kurtis with ease." />
                <meta name="keywords" content="how to order RCI Chikankari, ordering guide, purchase Chikankari kurtis, how to buy handmade kurtis, RCI Chikankari shopping steps" />
                <meta property="og:title" content="How to Order - RCI Chikankari" />
                <meta property="og:description" content="Step-by-step instructions on how to order from RCI Chikankari. Discover how to browse, select, and purchase our premium handmade Chikankari kurtis online." />
                <meta property="og:url" content="https://rcichikankari.com/howtoorder" />
                <link rel="canonical" href="https://rcichikankari.com/howtoorder" />
            </Helmet>
            <div className='productpage_head_container'>
                <div className='productpage_head'>
                    <p>How To Order</p>
                </div>
                <div className='productpage_subhead'>
                    <p>Home / How to Order</p>
                </div>
            </div>
            <div className='policypage'>
                <div className='policypage_container'>
                    <div className='policypage_para'>
                        <p>Ordering from RCI is quick and easy. Follow these simple steps to place your order:</p>
                    </div>
                    <div className='policypage_listorder'>
                        <ol>
                            <li>
                                <div className='policypage_listorder_head'><p>Browse our Website:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Explore our website and navigate through the product categories or use the search bar to find the items you wish to purchase.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Select Your Items: </p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Click on the desired item to view detailed product information, including size, color options, and price. Choose the options that best suit your preferences.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Add to Cart:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Once you have selected your items, click the "Add to Cart" button. You can continue shopping and add more items to your cart or proceed to checkout.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Review Your Cart:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Visit your shopping cart to review the items you have added. Here, you can update quantities, remove items, or apply any available discount codes.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Proceed to Checkout:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>When you are ready to place your order, click the "Checkout" button. You will be directed to the secure checkout page.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Provide Shipping Information:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Enter your shipping address and contact details accurately. Ensure that the information is complete to avoid any delivery issues.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Select a Payment Method</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Choose your preferred payment method from the available options. We accept various payment methods, including credit/debit cards, PayPal, and bank transfers.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Review and Place Your Order:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Double-check your order details, including the items, quantities, shipping address, and payment method. If everything is correct, click the "Place Order" button to confirm your purchase.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Order Confirmation:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Once your order is successfully placed, you will receive an order confirmation email. This email will include your order number and a summary of your purchase.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Track Your Order:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We will keep you informed about the progress of your order. You will receive updates via email, including a tracking number once your order is dispatched.</li>
                                    </ul>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>If you encounter any issues or need assistance during the ordering process, our friendly customer support team is available to help you. Simply reach out to us through the provided contact channels.</p>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>We have a designated Customer support Team which can place order for you smoothly and hassle free. You just need to contact on 8810934396 or 9369283751. Our Customer Service Representative will ask you details about your order and place the order for you.</p>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>We also have a Video- Call facility by which you can be ensured about the product design and work before ordering.</p>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>Thank you for choosing RCI. We appreciate your business and look forward to delivering a seamless shopping experience.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Howtoorder