import React, { useState, useEffect } from 'react';
import { useProfile, useUser } from '../../stateManagement/UserContext';
import { Link } from 'react-router-dom'
import img1 from './avtar.jpg';
import './myaccount.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import toast from 'react-hot-toast';
import Order from './Order';

const Myaccount = () => {
  const { user, logout } = useUser();
  const [data, setData] = useState(false);
  // eslint-disable-next-line
  const [dataorder, setDataorder] = useState(false)
  const { profile } = useProfile();
  const [orders, setOrder] = useState([]);
  // eslint-disable-next-line
  const [itemselect, setItemselect] = useState(0);

  const logoutfun = () => {
    logout();
  }

  useEffect(() => {
    // Check if profile message indicates no data found, then set data state to true
    if (profile && profile.message === 'data found for the specified ID.') {
      setData(true);
    }
    else {
      setData(false)
    }
    // eslint-disable-next-line
  }, [profile.message]); // Only re-run effect if profile.message changes


  const [postData, setPostData] = useState({
    user_id: user.user_id,
    user_gmail: user.user_email,
    user_name: user.user_name,
    phone: '',
    second_number: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    gender: '',
    DOB: ''
  });

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api =  process.env.REACT_APP_API_DASH_A;
  const dataenv = process.env.REACT_APP_DATA;


  const order_accept = () => {
    if (!validateFormData(postData)) {
      toast.error("NO, FIND ERROR");
      return; 
    }

    axios.post(`https:${api}`, postData, {
      headers: {
        "Content-Type": `${dataenv}`,
        Authorization: `Bearer ${jwt}`
      }
    }
    )
      .then((response) => {
        console.log(response.data);
        toast.success("successfully Submit");
        // toast.warn("please referesh the page")
        toast.custom(<div >please referesh the page</div>,
          {
            type: 'custom', // specify custom type
          }
        );
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  };

  const validateFormData = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !data[key]) {
        return false; 
      }
    }
    return true; 
  };

  const bdesh = process.env.REACT_APP_API_DASH_B

  const profile_views = () => {
    if (user) {
      axios.get(`https:${bdesh}?user_id=${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
      })
        .then((response) => {
          setOrder(response.data)
        })
        .catch((error) => {
          console.error('Error adding to cart:', error);
        });
    }
  };

  useEffect(() => {
    profile_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const personaldetailsfun = () => {
    setItemselect(0)
  }

  const orderselectfun = () => {
    setItemselect(1)
  }
  useEffect(() => {
    if (orders.message === 'No data found for the specified ID.') {
      setDataorder(false);
    }
    else {
      setDataorder(true)
    }
    // eslint-disable-next-line
  }, [orders.message]);

  return (
    <>
      <div className='productpage_head_container'>
        <div className='productpage_head'>
          <p>Account</p>
        </div>
        <div className='productpage_subhead'>
          <p>Home / MyAccount</p>
        </div>
      </div>
      <div className='myaccount_profile'>
        <div className='myaccount_profile_container'>
          <div className='myaccount_profile_container_img'><img src={img1} alt="" width='100%' height='100%' /></div>
          <div className='myaccount_profile_container_email'><p><span>Email : </span>{user.user_email}</p></div>
          <div className='myaccount_profile_container_logout_button' onClick={logoutfun}><p>Logout</p></div>
        </div>
      </div>
      <div className='myaccount_mainpart'>
        <div className='myaccount_navbar_left'>
          <div className={`myaccount_navbar_button ${itemselect === 0 && "selectnavbar"}`} onClick={personaldetailsfun}>
            <p>Personal Details</p>
          </div>
          <div className={`myaccount_navbar_button ${itemselect === 1 && "selectnavbar"}`} onClick={orderselectfun}>
            <p>Order</p>
          </div>
          <div className='myaccount_navbar_button'>
            <Link to='/addtocart/specialproduct'><p>Add to cart</p></Link>
          </div>
          <div className='myaccount_navbar_button'>
            <Link to='/wishtocart/wishproduct'><p>Wishlist</p></Link>
          </div>
        </div>
        {
          itemselect === 0
          &&
          <div className='myaccount_right'>
            {!data ?
              <>
                <div className='ship_head'><p>Personal Details </p></div>
                <div className='ship_input-container'>
                  <div className='wrap_ship'>
                    <div className='ship_input'><input type="text" placeholder='pincode' value={postData.pincode} id="pin" pattern="[0-9]{6}" onChange={e => setPostData({ ...postData, pincode: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="Address" value={postData.address} onChange={e => setPostData({ ...postData, address: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="City/town" value={postData.city} onChange={e => setPostData({ ...postData, city: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="Mobile number" value={postData.phone} onChange={e => setPostData({ ...postData, phone: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="alternate number" value={postData.second_number} onChange={e => setPostData({ ...postData, second_number: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="State" value={postData.state} onChange={e => setPostData({ ...postData, state: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="pincode" value={postData.pincode} onChange={e => setPostData({ ...postData, pincode: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="D.O.B." value={postData.DOB} onChange={e => setPostData({ ...postData, DOB: e.target.value })} /></div>
                    <div className='ship_input'><input type="text" placeholder="gender" value={postData.gender} onChange={e => setPostData({ ...postData, gender: e.target.value })} /></div>
                  </div>
                </div>
                <div className='Account_submit' onClick={order_accept}>
                  <p>Submit</p>
                </div>
              </>
              :
              <>
                {
                  <div className='myaccount_main_part2_details'>
                    <div className='ship_head'><p>Personal Details </p></div>
                    <div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span>Name : </span> {profile[0][0].user_name}</p>
                      </div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span>Phone : </span> {profile[0][0].phone}</p>
                      </div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span>Other Phone : </span> {profile[0][0].second_number}</p>
                      </div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span>Address : </span> {profile[0][0].address}</p>
                      </div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span> DOB: </span> {profile[0][0].DOB}</p>
                      </div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span> city: </span> {profile[0][0].city}</p>
                      </div>
                      <div className='myaccount_profile_container_email'>
                        <p> <span> pincode: </span> {profile[0][0].pincode}</p>
                      </div>
                    </div>
                  </div>
                }

              </>}
          </div>
        }
        {
          itemselect === 1 &&
          <>
             <Order/>
          </>
        }
      </div>

    </>
  );
};

export default Myaccount;
