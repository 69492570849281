import "./terms.css";
import { Helmet } from 'react-helmet';
const Term = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - RCI Chikankari</title>
        <meta name="description" content="Read the terms and conditions of RCI Chikankari. By using our website and purchasing our handmade Chikankari kurtis, you agree to our terms and conditions." />
        <meta name="keywords" content="RCI Chikankari terms and conditions, terms of service, user agreement, e-commerce terms, Chikankari kurtis purchase terms, website usage terms" />
        <meta property="og:title" content="Terms and Conditions - RCI Chikankari" />
        <meta property="og:description" content="By using RCI Chikankari’s website, you agree to our terms and conditions. Read more about your rights, obligations, and limitations when shopping with us." />
        <meta property="og:url" content="https://rcichikankari.com/termandcondition" />
        <link rel="canonical" href="https://rcichikankari.com/termandcondition" />
      </Helmet>
      <div className="productpage_head_container">
        <div className="productpage_head">
          <p>Term & Condition</p>
        </div>
        <div className="productpage_subhead">
          <p>Home / Term & Condition</p>
        </div>
      </div>
      <div className="policypage">
        <div className="policypage_container">
          <div className="policypage_para">
            <p>
              Welcome to RCI. These terms and conditions outline the rules and
              regulations for the use of our website. By accessing or using our
              website, you accept and agree to be bound by these terms and
              conditions. If you do not agree with any part of these terms,
              please refrain from using our website.
            </p>
          </div>
          <div className="policypage_listorder">
            <ol>
              <li>
                <div className="policypage_listorder_head">
                  <p>Intellectual Property:</p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      All content, materials, and trademarks displayed on our
                      website are the intellectual property of RCI and are
                      protected by applicable copyright and trademark laws.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="policypage_listorder_head">
                  <p>Product Information: </p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      We strive to provide accurate and up-to-date product
                      information on our website. However, we do not warrant the
                      completeness, accuracy, or reliability of any product
                      descriptions or images.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="policypage_listorder_head">
                  <p>Pricing and Payments: </p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      We accept various payment methods as indicated on our
                      website. By making a purchase, you agree to provide
                      accurate and complete payment information.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="policypage_listorder_head">
                  <p>Shipping and Delivery:</p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      We aim to process and ship orders promptly. Delivery times
                      may vary depending on your location and other factors
                      beyond our control.
                    </li>
                    <li>
                      Any estimated delivery dates provided are not guaranteed
                      and are subject to change.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="policypage_listorder_head">
                  <p>Returns and Exchanges:</p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      Please refer to our Return Policy for information
                      regarding returns and exchanges.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="policypage_listorder_head">
                  <p>Limitation of Liability:</p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      RCI shall not be liable for any direct, indirect,
                      incidental, consequential, or punitive damages arising
                      from the use or inability to use our website or products.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="policypage_listorder_head">
                  <p>Governing Law:</p>
                </div>
                <div className="policypage_listunorder">
                  <ul>
                    <li>
                      These terms and conditions shall be governed by and
                      interpreted in accordance with the laws of India, without
                      regard to its conflict of law provisions.
                    </li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
          <div className="policypage_lastpara">
            <p>
              If you have any questions or concerns regarding our terms and
              conditions, please contact us.
            </p>
          </div>
          <div className="policypage_lastpara">
            <p>Operational Address :</p>
            <p>Rupesh chikan industries</p>
            <p>325/81, sondhi tola, chowk lko</p>
            <p>Landmark: SS PHARMA</p>
            <p>pincode: 226003</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Term;
