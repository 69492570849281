import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../auth.css';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import toast from 'react-hot-toast';
const SignIn = () => {
    const form = useRef();
    const [data, setData] = useState({});
    const [message, setMessage] = useState('')
    const [nextotp, setNextotp] = useState(false);
    const [otp, setOtp] = useState('');
    const [apiotp, setApiotp] = useState('');
    const secret = 'youraregood';

    const payload = {
        iss: "ecommerce",
        aud: "yourname"
    };

    const header = {
        "alg": 'HS256',
    }
    const navigate = useNavigate();


    const submithandle = (event) => {
        event.preventDefault();
        if (data.user_name && data.email && data.password ) {
            const FormData1 = data;
            const encodeHeader = btoa(JSON.stringify(header));
            const payloadString = btoa(JSON.stringify(payload));
            const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secret).toString(CryptoJS.enc.Base64);
            const jwt = `${encodeHeader}.${payloadString}.${signature}`;

            axios.post('https://admin.rcichikankari.com/customer/Checkmail.php', FormData1, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                },
            }).then((res) => {
                setMessage(res.data);

            }).catch((error) => {
                console.log(error);
            })
        }
        else{
            toast.error("Please Enter Full details");
        }
    }

    const otphandle = () => {
        const FormData1 = data;
        const encodeHeader = btoa(JSON.stringify(header));
        const payloadString = btoa(JSON.stringify(payload));
        const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secret).toString(CryptoJS.enc.Base64);
        const jwt = `${encodeHeader}.${payloadString}.${signature}`;
        axios.post('https://admin.rcichikankari.com/customer/otp.php', FormData1, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        }).then((res) => {
            setApiotp(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }


    const submitregistration = () => {
        const FormData1 = data;
        const encodeHeader = btoa(JSON.stringify(header));
        const payloadString = btoa(JSON.stringify(payload));
        const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secret).toString(CryptoJS.enc.Base64);
        const jwt = `${encodeHeader}.${payloadString}.${signature}`;
        axios.post('https://admin.rcichikankari.com/customer/Registration.php', FormData1, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        }).then((res) => {
            setMessage(res.data);
            toast.success("Successfully Create Your Account");
            toast.success("Please Login Your Account");
            navigate('/login');
        }).catch((error) => {
            console.log(error);
        })
    }

    const checkotp = () => {
        if (apiotp.otp === otp) {
            toast.success("OTP Verify");
            submitregistration();
        }
        else {
            toast.error("Wrong OTP");
        }
    }
    const checkauth = () => {
        if (message.message === "User with this email already registered") {
            toast.error("User Already registered");
        }
        else if (message.message === "Error registering user") {
            toast.error("Error Sign In");
        }
        else if (message.message === "User with this email not already registered") {
            setNextotp(true);
            otphandle();
        }
    }

    useEffect(() => {
        checkauth();
        // eslint-disable-next-line 
    }, [message]);


    return (
        <>
            <div className='auth_conatiner'>
                <div></div>
                <div className='login'>
                    {!nextotp ?
                        <div className='login_part2'>
                            <form ref={form} onSubmit={submithandle} className='login_form'>
                                <div className='login_head'><p>Sign In</p></div>
                                <div className='login_input'>
                                    <input type="text" placeholder="Enter Name" onChange={(e) => setData({ ...data, user_name: e.target.value })} />
                                    <input type="email" placeholder="Enter Email" onChange={(e) => setData({ ...data, email: e.target.value })} />
                                    <input type="password" placeholder="Enter Password" onChange={(e) => setData({ ...data, password: e.target.value })} minlength="8" maxlength="12" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).*$" />
                                </div><div className='part2_button'> <button type='submit' className='login_button'>Submit</button></div>
                            </form>
                            <hr />
                            <div className='link_signin'><Link to='/login'> Login your account</Link></div>
                            <hr />
                            <div className='password_ristrictions'>
                                <p>Your Password Must Include:</p>
                                <ol>
                                    <li>At least one uppercase letter (A-Z)</li>
                                    <li>At least one lowercase letter (a-z)</li>
                                    <li>At least one digit (0-9)</li>
                                    <li>At least one special character (e.g., @, #, $, etc.)</li>
                                </ol>
                                <p>Example: Password@123</p>
                            </div>
                        </div>
                        :
                        <>
                            <div className='otp_singup'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle="otp-input"
                                />
                            </div>
                            <div className='otp_button_signup'>
                                <div className='resend_button'>
                                    <button onClick={otphandle}>Resend</button>
                                </div>
                                <div className='send_button_signup'>
                                    <button onClick={checkotp}>Verify</button>
                                </div>
                                
                            
                            </div>
                            <hr />
                            <div className='password_ristrictions'>
                                <p>Your OTP may take up to 1 minute to arrive. If the OTP doesn't appear in your inbox, please check your spam or junk folder as well. If you don't receive the OTP within 1 minute, you can click 'Resend OTP'. Always enter the most recent OTP you receive</p>
                                
                            </div>

                        </>}
                </div>
            </div >

        </>
    )
}

export default SignIn