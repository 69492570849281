import React, { useEffect, useState } from "react";
import { useUser } from "../../stateManagement/UserContext";
import axios from "axios";
import CryptoJS from "crypto-js";
import Loading from "../../Component/loading/Loading";
import "./order.css";
import { Link } from "react-router-dom";

const Order = () => {
  const { user } = useUser();
  const [status, setStatus] = useState(true);
  const [status2, setStatus2] = useState(true);
  const [singleorder, setSingleOrder] = useState(false);
  const [addtocartorder, setAddtocartOrder] = useState(true);
  const [orders, setOrder] = useState([]);
  const [addorders, setAddOrder] = useState([]);

  let totalDiscount = 0;

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_DASH_B;

  const profile_views = () => {
    if (user) {
      axios
        .get(`https:${api}?user_id=${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          if (response.data.message === "Order data found") {
            console.log(response.data.Order_cart)
            setOrder(response.data.Order_cart);
            setStatus(false);
            setSingleOrder(true);
          } else {
            setStatus(false);
            setSingleOrder(false);
          }
        })
        .catch((error) => {
          console.error("Error adding to cart:", error);
        });
    }
  };

  useEffect(() => {
    profile_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carttoadd = process.env.REACT_APP_API_DASH_C;

  const Addtocart_order = () => {
    if (user) {
      axios
        .get(`https:${carttoadd}?user_id=${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          if (response.data.message === "Order data found") {
            setAddOrder(response.data.Order_cart);
            setStatus2(false);
            setAddtocartOrder(true);
          } else {
            setStatus2(false);
            setAddtocartOrder(true);
          }
        })
        .catch((error) => {
          console.error("Error adding to cart:", error);
        });
    }
  };

  useEffect(() => {
    Addtocart_order();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status || status2) {
    return <Loading />;
  }

  return (
    <>
      <div className="order_container">
        {singleorder &&
          orders.map((item) => (
            <div className="order_single_product_container">
              <div className="order_part1">
                <div className="order_id">
                  <p>
                    {" "}
                    ORDER ID : <span> {item.order_unique_id} </span>{" "}
                  </p>
                </div>
                <div className="order_track">
                  <p>
                    {item.Track_product === "" ? (
                      ""
                    ) : (
                      <a href={item.Track_product} target="__blank">
                        {" "}
                        Track order{" "}
                      </a>
                    )}
                  </p>
                </div>
              </div>
              <div className="order_part2">
                <div className="order_date_part2">
                  <p>
                    Order date : <span>{item.order_date}</span>
                  </p>
                </div>
                <div className="order_part2_space"></div>
                <hr />
                <div className="order_estimate_part2">
                  <p>
                    Estimated delivery :{" "}
                    <span>
                      {item.expected_date === "" ? (
                        <p> Coming Soon </p>
                      ) : (
                        item.expected_date
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <hr />
              <div className="order_part3">
                <div className="order_part3_left">
                  <div className="order_image_part3">
                    <img
                      src={
                        item.product_details.catalog
                          ? `${process.env.REACT_APP_API_IMAGE}${item.product_details.thumnail}`
                          : `${process.env.REACT_APP_API_IMAGE_SECOND}${item.product_details.thumnail}`
                      }
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div className="order_image_name_part3">
                    <div className="order_image_name_part3_name_p">
                      <p>{item.product_details.name}</p>
                    </div>
                    <div className="order_image_name_part3_size_p">
                      <p>Size :{item.size}</p>
                    </div>
                  </div>
                </div>
                <div className="order_part3_right">
                  <div className="order_part3_right_price">
                    <p>Rs:- {item.product_details.discount}</p>
                  </div>
                  <div className="order_part3_right_qun">
                    <p>Qty :- {item.pro_quantity}</p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="order_part4">
                <div className="payment_order_mode">
                  <div className="order_head_part4">
                    <p>Payment</p>
                  </div>
                  <div className="order_part4_p">
                    <p>{item.payment}</p>
                  </div>
                </div>
                <div className="Address_order">
                  <div className="order_head_part4">
                    <p>Delivery</p>
                  </div>
                  <div className="order_subhead_part4">
                    <p>Address</p>
                  </div>
                  <div className="order_part4_p">
                    <p>{item.pincode}</p>
                  </div>
                  <div className="order_part4_p">
                    <p>{item.Address}</p>
                  </div>
                  <div className="order_part4_p">
                    <p>{item.location}</p>
                  </div>
                  <div className="order_part4_p">
                    <p>Phone No. :- {item.phone}</p>
                  </div>
                  <div className="order_part4_p">
                    <p>Alt No. :- {item.alt_phone}</p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="order_part5">
                <div className="order_part5_left">
                  <div className="order_head_part4">
                    <p> Need Help ?</p>
                  </div>
                  <div className="order_part5_link">
                    <Link to="/shipping">Shipping Policy &#8599;</Link>
                  </div>
                  <div className="order_part5_link">
                    <Link to="/howtoorder">How to Order &#8599;</Link>
                  </div>
                  <div className="order_part5_link">
                    <Link to="/return">Return & Refunds &#8599;</Link>
                  </div>
                </div>
                <div className="order_part5_right">
                  <div className="order_head_part4">
                    <p>Order Summary</p>
                  </div>
                  <div className="order_price_summary">
                    <div className="order_price_summary_item">
                      <div className="order_price_summary_item_p">
                        <p> Price :</p>
                      </div>
                      <div className="order_price_summary_item_p">
                        <p> Qty :</p>
                      </div>
                      <div className="order_price_summary_item_p">
                        <p> Sub Total : </p>
                      </div>
                      <div className="order_price_summary_item_p">
                        <p> Couple Discount :</p>
                      </div>
                      <div className="order_price_summary_item_p">
                        <p> Delivery :</p>
                      </div>
                      
                      <hr />
                      <div className="order_price_summary_item_p">
                        <p> Total Amount:</p>
                      </div>
                    </div>
                    <div className="order_price_summary_money">
                      <div className="order_price_summary_money_p">
                        <p>{item.product_details.discount}</p>
                      </div>
                      <div className="order_price_summary_money_p">
                        <p>{item.pro_quantity}</p>
                      </div>
                      <div className="order_price_summary_money_p">
                        <p>
                          {item.product_details.discount * item.pro_quantity}
                        </p>
                      </div>
                      <div className="order_price_summary_money_p">
                        <p>
                          -{" "}
                          {item.payment === "Cash on delivery" ?<>
                          {item.product_details.discount * item.pro_quantity -
                            item.amount+ 50}
                            </>:<>{item.product_details.discount * item.pro_quantity -
                            item.amount}</>}
                        </p>
                      </div>
                      <div className="order_price_summary_money_p">
                        <p> {item.payment === "Cash on delivery" ? "50" : "0"}</p>
                      </div>
                      <hr />
                      <div className="order_price_summary_money_p">
                        <p> {item.amount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {addtocartorder &&
          addorders.map((item) => (
            <>
              <p className="hide">{(totalDiscount = 0)}</p>
              <div className="order_single_product_container">
                <div className="order_part1">
                  <div className="order_id">
                    <p>
                      {" "}
                      ORDER ID : <span> {item.order_unique_id} </span>{" "}
                    </p>
                  </div>
                  <div className="order_track">
                    <p>
                      {item.Track_product === "" ? (
                        ""
                      ) : (
                        <a href={item.Track_product} target="__blank">
                          {" "}
                          Track order{" "}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                <div className="order_part2">
                  <div className="order_date_part2">
                    <p>
                      Order date : <span>{item.order_date}</span>
                    </p>
                  </div>
                  <div className="order_part2_space"></div>
                  <hr />
                  <div className="order_estimate_part2">
                    <p>
                      Estimated delivery :{" "}
                      <span>
                        {item.expected_date === "" ? (
                          <p> Coming Soon </p>
                        ) : (
                          item.expected_date
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <hr />
                {/* {console.log(item.product_details[0])} */}

                {item.product_details.map((items) => (
                  <div className="order_part3">
                    <div className="order_part3_left">
                      <div className="order_image_part3">
                        <img
                          src={
                            items.catalog
                              ? `${process.env.REACT_APP_API_IMAGE}${items.thumnail}`
                              : `${process.env.REACT_APP_API_IMAGE_SECOND}${items.thumnail}`
                          }
                          width="100%"
                          height="100%"
                          alt=""
                        />
                      </div>
                      <div className="order_image_name_part3">
                        <div className="order_image_name_part3_name_p">
                          <p>{items.name}</p>
                        </div>
                        <div className="order_image_name_part3_size_p">
                          {item.product_qun.map((qun_item) => (
                            <>
                              {items.unique_id === qun_item.product_id && (
                                <p>Size :{qun_item.size}</p>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="order_part3_right">
                      <div className="order_part3_right_price">
                        <p>Rs:- {items.discount}</p>
                      </div>
                      <div className="order_part3_right_qun">
                        {item.product_qun.map((qun_item) => (
                          <>
                            {items.unique_id === qun_item.product_id && (
                              <p>Qty :- {qun_item.qun}</p>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}

                <hr />
                <div className="order_part4">
                  <div className="payment_order_mode">
                    <div className="order_head_part4">
                      <p>Payment</p>
                    </div>
                    <div className="order_part4_p">
                      <p>{item.payment}</p>
                    </div>
                  </div>
                  <div className="Address_order">
                    <div className="order_head_part4">
                      <p>Delivery</p>
                    </div>
                    <div className="order_subhead_part4">
                      <p>Address</p>
                    </div>
                    <div className="order_part4_p">
                      <p>{item.pincode}</p>
                    </div>
                    <div className="order_part4_p">
                      <p>{item.Address}</p>
                    </div>
                    <div className="order_part4_p">
                      <p>{item.location}</p>
                    </div>
                    <div className="order_part4_p">
                      <p>Phone No. :- {item.phone}</p>
                    </div>
                    <div className="order_part4_p">
                      <p>Alt No. :- {item.alt_phone}</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="order_part5">
                  <div className="order_part5_left">
                    <div className="order_head_part4">
                      <p> Need Help ?</p>
                    </div>
                    <div className="order_part5_link">
                      <Link to="/shipping">Shipping Policy &#8599;</Link>
                    </div>
                    <div className="order_part5_link">
                      <Link to="/howtoorder">How to Order &#8599;</Link>
                    </div>
                    <div className="order_part5_link">
                      <Link to="/return">Return & Refunds &#8599;</Link>
                    </div>
                  </div>
                  <div className="order_part5_right">
                    <div className="order_head_part4">
                      <p>Order Summary</p>
                    </div>
                    <div className="order_price_summary">
                      <div className="order_price_summary_item">
                        <div className="order_price_summary_item_p">
                          {item.product_details.map((items, index) => (
                            <p>Price Item {index + 1}</p>
                          ))}
                          {/* s<p> Price :</p> */}
                        </div>
                        <div className="order_price_summary_item_p">
                          {item.product_details.map((items, index) => (
                            <p> Qty Item {index + 1}</p>
                          ))}
                          {/* <p> Qty :</p> */}
                        </div>
                        <div className="order_price_summary_item_p">
                          <p> Sub Total : </p>
                        </div>
                        <div className="order_price_summary_item_p">
                          <p> Coupon Discount :</p>
                        </div>
                        <div className="order_price_summary_item_p">
                          <p> Delivery :</p>
                        </div>
                        <hr />
                        <div className="order_price_summary_item_p">
                          <p> Total Amount:</p>
                        </div>
                      </div>
                      <div className="order_price_summary_money">
                        <div className="order_price_summary_money_p">
                          {item.product_details.map((items) => (
                            <p>{items.discount}</p>
                          ))}
                        </div>
                        <div className="order_price_summary_money_p">
                          {item.product_qun.map((qun_item) => (
                            <p>{qun_item.qun}</p>
                          ))}
                          <p>{item.pro_quantity}</p>
                        </div>
                        <div className="order_price_summary_money_p">
                          {item.product_details.map((items) => (
                            <>
                              {item.product_qun.map((qun_item) => {
                                if (items.unique_id === qun_item.product_id) {
                                  const productTotal =
                                    items.discount * qun_item.qun;
                                  totalDiscount += productTotal; // Accumulate the total discount
                                }
                                return null;
                              })}
                            </>
                          ))}
                          <p>{totalDiscount}</p>
                        </div>
                        <div className="order_price_summary_money_p">
                          <p>- {item.payment === "Cash on delivery" ? totalDiscount - item.amount + 50 :totalDiscount - item.amount}</p>
                        </div>
                        <div className="order_price_summary_money_p">
                          <p> {item.payment === "Cash on delivery" ? 50 : 0} </p>
                        </div>
                        <hr />
                        <div className="order_price_summary_money_p">
                          <p> {item.amount}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default Order;
