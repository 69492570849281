import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Loading from "../../Component/loading/Loading";
const Sets = () => {
  const [cart, setCart] = useState([]);
  const [status, setStatus] = useState(true);
  const [status2, setStatus2] = useState(false);

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_SETS_A;

  const cart_views = () => {
    axios
      .get(`https:${api}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        setCart(response.data);
        if (response.data.message === "No data found") {
          setStatus(false);
        } else {
          setStatus(false);
          setStatus2(true);
        }
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
      });
  };

  useEffect(() => {
    cart_views();
    // eslint-disable-next-line
  }, []);

  if (status) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="productpage_head_container">
        <div className="productpage_head">
          <p> Sets </p>
        </div>
        <div className="productpage_subhead">
          <p>Home / Sets </p>
        </div>
      </div>
      <div className="pro_home_container">
        <div className="pro_home">
          {status2 ? (
            cart.map((item, index) => (
              <div key={index}>
                <Link to={`/productsingle/${item.id}?sizes=sets/viewpro.php`}>
                  <div className="pro_part">
                    <div className="product_box_sliding_container_img">
                      <div className="home_product_box_image">
                        <img
                          src={`${process.env.REACT_APP_API_IMAGE}${item.thumnail}`}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                    <div className="pro_name">
                      <p>{item.name}</p>
                    </div>
                    <div className="pro_price">
                      <p>Rs-{item.discount}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div>Not Data</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sets;
