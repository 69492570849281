
import './terms.css';
import { Helmet } from 'react-helmet';
const Return = () => {
    return (
        <>
            <Helmet>
                <title>Exchange, Returns, and Cancellation Policy - RCI Chikankari</title>
                <meta name="description" content="Read RCI Chikankari's policy on exchanges, returns, and cancellations. Find out how to return or exchange your handmade Chikankari kurtis and our guidelines for order cancellations." />
                <meta name="keywords" content="RCI Chikankari exchange policy, return policy, cancellation policy, refund policy, Chikankari kurtis returns, product exchanges" />
                <meta property="og:title" content="Exchange, Returns, and Cancellation Policy - RCI Chikankari" />
                <meta property="og:description" content="Learn about RCI Chikankari’s policies for exchanges, returns, and cancellations. Discover how to handle product returns, exchanges, and order cancellations." />
                <meta property="og:url" content="https://rcichikankari.com/return" />
                <link rel="canonical" href="https://rcichikankari.com/return" />
            </Helmet>
            <div className='productpage_head_container'>
                <div className='productpage_head'>
                    <p>Exchange Returns & Cancellation</p>
                </div>
                <div className='productpage_subhead'>
                    <p>Home / Exchange Returns & Cancellation</p>
                </div>
            </div>
            <div className='returnpage_container' >
                <div className='returnpage_subcontainer' >
                    <div className='returnpage_head'><p>Exchange Policy:</p></div>
                    <div className='returnpage_subhead'><p>If you wish to Exchange your order, or some items in your order ( PAN India):</p></div>
                    <div className='returnpage_para'><p>At RCI, we strive to ensure customer satisfaction with every purchase. In the event that you are not completely satisfied with your order, we offer exchange policy on certain situations to provide you with the best shopping experience.</p></div>
                    <div className='returnpage_para'><p>Call/Whatsapp- <span className="contact_returnpage"> 9305299500 or 9839122700 , support@rcichikankari.com </span> </p></div>
                    <div className='returnpage_pointpart'>
                        <div className='returnpage_pointhead'><p>Exchange Eligibility:</p></div>
                        <div className='policypage_listunorder'>
                            <ul>
                                <li>The Exchange is free only if wrong/defective product has been delivered from our end, the team will generate reverse pickup after inspection and send you the new parcel.</li>
                                <li>If the desired item is unavailable, we can issue store credit or assist you in selecting an alternative product.</li>
                                <li>The only other condition can be when you have a size issue, charges of delivery has to be paid by the customer in such a case and exchanged product will be sent from our team.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='returnpage_pointpart'>
                        <div className='returnpage_pointhead'><p>Terms:</p></div>
                        <div className='policypage_listunorder'>
                            <ul>
                                <li>

                                </li>
                                <li>The item(s) must be unused, in its original packaging, and in the same condition as received.</li>
                                <li>Personalized or customized items may not be eligible for exchange, unless there is a manufacturing defect.</li>
                                <li>We recommend to make a parcel opening video when you receive the package to avoid any disputes.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='returnpage_pointpart'>
                        <div className='returnpage_pointhead'><p>Exchange Process:</p></div>
                        <div className='policypage_listunorder'>
                            <ul>
                                <li>To initiate an exchange, please contact our customer support team within the specified time frame. <br />( Call/Whatsapp- <span className="contact_returnpage">9305299500 or 9839122700, support@rcichikankari.com</span> )</li>
                                <li>Provide relevant details such as order number, item(s) to be exchanged, and reason for exchange.</li>
                                <li>Our customer support team will guide you through the exchange process and provide further instructions.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='returnpage_pointpart'>
                        <div className='returnpage_pointhead'><p>Return Shipping:</p></div>
                        <div className='policypage_listunorder'>
                            <ul>
                                <li>The shipping is free of the exchange order if it’s an error from our end, example- defective product.</li>
                                <li>But the customer is responsible for the shipping costs associated with returning the item(s) for exchange due to size problem. We charge fixed ₹250 for generating a reverse pickup from your location and sending a new product from our end.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='returnpage_pointpart'>
                        <div className='returnpage_pointhead'><p>Inspection and Approval:</p></div>
                        <div className='policypage_listunorder'>
                            <ul>
                                <li>Once we receive the returned item(s), our team will inspect them to ensure they meet the eligibility criteria.</li>
                                <li>Upon approval, we will process the exchange and provide you with the updated tracking details.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='returnpage_para'><p>Please note that our exchange policy may be subject to change, and any modifications will be communicated on our website. For further details or clarification, please refer to our website or contact our customer support team.</p></div>
                    <div className='returnpage_para'><p>Thank you for choosing RCI. We value your satisfaction and are committed to providing you with a seamless exchange process.</p></div>
                    <div className='returnpage_subhead'><p>If you wish to Exchange your order, or some items in your order ( International Orders):</p></div>
                    <div className='returnpage_para'><p>At RCI, we value our international customers and strive to provide an exceptional shopping experience. However, please note that currently, we do not offer exchanges for international orders. We apologize for any inconvenience this may cause.</p></div>
                    <div className='returnpage_para'><p>To ensure a smooth and satisfactory purchase, we encourage our international customers to carefully review product details, size charts, and specifications before placing an order. If you have any questions or concerns, our customer support team is available to assist you prior to making your purchase.</p></div>
                    <div className='returnpage_para'><p>We take great care in packaging and quality control to minimize any issues with international orders.</p></div>
                    <div className='returnpage_para'><p>If you have any further questions or require clarification, please do not hesitate to contact our customer support team ( Call/Whatsapp- <span className="contact_returnpage">9305299500 or 9839122700, support@rcichikankari.com</span>). Thank you for choosing RCI. We appreciate your support and look forward to serving you.</p></div>
                    <div className='returnpage_subhead'><p>Return Policy:</p></div>
                    <div className="returnpage_para"><p>At RCI , we strive to provide you with the best shopping experience and high-quality products. However, please note that <strong>we do not accept returns for any.</strong> </p></div>
                    <div className="returnpage_para"><p>We take utmost care in packaging and quality control to ensure that your order reaches you in perfect condition. In the unlikely event that you receive a damaged or defective item, please contact our customer support team within 3 days of receiving your order. We will review the issue on a case-by-case basis and work towards a suitable resolution, which may include a replacement depending on the circumstances.</p></div>
                    <div className="returnpage_para"><p>To assist us in resolving the issue promptly, please provide detailed information such as your order number, a clear description or images of the damaged or defective item(s), and any other relevant details. Our customer support team will guide you through the necessary steps to initiate the resolution process.</p></div>
                    <div className="returnpage_para"><p>We appreciate your understanding and cooperation regarding our return policy. If you have any further questions or concerns, please do not hesitate to contact our customer support team. Thank you for choosing RCI. We appreciate your support and look forward to serving you.</p></div>
                    <div className='returnpage_subhead'><p>Cancellation/ Modification Policy:</p></div>
                    <div className="returnpage_para"><p>The cancellation/ modification depends on the circumstances-</p></div>
                    <div className="policypage_listunorder returnpage_unorderlist">
                        <ul>
                            <li>If the order is not shipped, our support team can help you cancel/modify the order or some items of the order.</li>
                            <li>If the order is shipped, we regret to inform you that we are unable to accommodate any changes or cancellations.</li>
                        </ul>
                    </div>
                    <div className="returnpage_contacthead"><p>Contact us-</p></div>
                    <div className="returnpage_para"><p>Whatsapp/ Call on –<span className="contact_returnpage">9305299500 or 9839122700</span> </p></div>
                    <div className="returnpage_para"><p>Email: <span className="contact_returnpage">support@rcichikankari.com</span></p></div>
                </div>
            </div>
        </>
    )
}

export default Return