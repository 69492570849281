import './terms.css';
import { Helmet } from 'react-helmet';
const Shipping = () => {
    return (
        <>
            <Helmet>
                <title>Shipping and Delivery Policy - RCI Chikankari</title>
                <meta name="description" content="Read RCI Chikankari's shipping and delivery policy. Find out about shipping options, delivery times, and costs for our handmade Chikankari kurtis." />
                <meta name="keywords" content="RCI Chikankari shipping policy, delivery policy, shipping options, Chikankari kurtis delivery, shipping costs, order delivery times" />
                <meta property="og:title" content="Shipping and Delivery Policy - RCI Chikankari" />
                <meta property="og:description" content="Get details about our shipping and delivery services for Chikankari kurtis. Learn about shipping options, costs, and delivery times at RCI Chikankari." />
                <meta property="og:url" content="https://rcichikankari.com/shipping" />
                <link rel="canonical" href="https://rcichikankari.com/shipping" />
            </Helmet>
            <div className='productpage_head_container'>
                <div className='productpage_head'>
                    <p>Shipping And Delivery Policy</p>
                </div>
                <div className='productpage_subhead'>
                    <p>Home / Shipping and Delivery Policy</p>
                </div>
            </div>
            <div className='policypage'>
                <div className='policypage_container'>
                    <div className='policypage_para'>
                        <p>Thank you for choosing RCI. This shipping and delivery policy outlines the terms and conditions regarding the shipment and delivery of your order.</p>
                    </div>
                    <div className='policypage_listorder'>
                        <ol>
                            <li>
                                <div className='policypage_listorder_head'><p>Order Processing::</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We strive to process and dispatch orders promptly. Order processing times may vary and are subject to product availability and verification of payment. Orders are processed withing 48 hours of receiving the order.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Shipping Methods:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>We offer various shipping methods, including standard and express delivery options. The available shipping methods will be displayed at checkout.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Shipping Costs: </p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Shipping is completely FREE for Pre-paid orders but <strong>additional charge of ₹50 is to be charged on Cash on Delivery orders (PAN India only).</strong></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Delivery Time: </p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Estimated delivery times are provided as a guideline and may vary depending on your location and other factors beyond our control. We expect you to receive the product within 7 to 14 days after dispatch. Please note that any estimated delivery dates are not guaranteed.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Tracking:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Once your order has been dispatched, we will provide you with a tracking number. You can use this tracking number to monitor the progress of your shipment online.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Delivery Address:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Please ensure that the delivery address provided during checkout is accurate and complete. We are not responsible for delays or undelivered shipments due to incorrect or incomplete address information.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Delivery Attempts and Re-Delivery:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>Our shipping partners will attempt delivery to the provided address. If delivery is unsuccessful, they may make additional attempts or leave a notification for re-delivery or pickup at a designated location.</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className='policypage_listorder_head'><p>Damaged or Lost Shipments:</p></div>
                                <div className='policypage_listunorder'>
                                    <ul>
                                        <li>In the event that your shipment arrives damaged or is lost in transit, please contact our customer support team immediately. We will work with you to resolve the issue and initiate any necessary claims or investigations.</li>
                                    </ul>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className='policypage_lastpara'>
                        <p>Please note that our shipping and delivery policy may be subject to change. Any modifications will be communicated on our website. If you have any questions or concerns regarding our shipping and delivery policy, please contact our customer support team. We are here to assist you and ensure a smooth delivery of your order.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shipping