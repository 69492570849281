import React, { useEffect, useState } from "react";
import { useUser } from "../../stateManagement/UserContext";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Loading from "../../Component/loading/Loading";
import toast from "react-hot-toast";

const Wishlist = () => {
  const { user } = useUser();
  const [wishlist, setWishlist] = useState([]);
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_WISH_A;

  const wishlistViews = () => {
    if (user) {
      setLoading(true);
      setStatus(true);

      axios
        .get(`https:${api}=${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setWishlist(response.data.cart_items);
          if (response.data.message === "Cart items retrieved successfully.") {
            setStatus(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching wishlist:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after the API call is complete
        });
    }
  };

  const cartremove = process.env.REACT_APP_API_WISH_B;

  const remove_chart = (product_id) => {
    setLoading(true); // Set loading to true before the API call
    axios
      .delete(`https:${cartremove}=${user.user_id}&product_id=${product_id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (
          response.data.message === "Product removed from cart successfully."
        ) {
          wishlistViews(); // Refresh the wishlist after removal
        }
      })
      .catch((error) => {
        console.error("Error removing from cart:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call is complete
      });
  };

  useEffect(() => {
    wishlistViews();
    // eslint-disable-next-line
  }, []);

  const carttoadd = process.env.REACT_APP_API_WISH_D;

  const Add_chart = (e, item) => {
    e.preventDefault();
    axios
      .post(`https:${carttoadd}`, item, {
        headers: {
          "Content-Type": "multipart/dataToPost",
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.message === "Product added to cart successfully.") {
          toast.success("Addtocart");
          remove_chart(item.product_id);
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  if (loading) {
    return (
      <div>
        {" "}
        <Loading />{" "}
      </div>
    ); // Display Loading component while fetching data
  }

  if (status) {
    return <div> Not Found </div>;
  }

  return (
    <>
      <div className="pro_home_container">
        <div className="pro_home">
          {wishlist.map((item, index) => (
            <div key={index}>
              <>
                <Link
                  to={
                    item.product_details.catalog
                      ? `/productsingle/${item.product_details.id}?sizes=${item.product_details.catalog}/viewpro.php`
                      : `/product/${item.product_details.id}`
                  }
                >
                  <div className="pro_part">
                    <div className="product_box_sliding_container_img">
                      <div className="home_product_box_image">
                        <img
                          src={
                            item.product_details.catalog
                              ? `${process.env.REACT_APP_API_IMAGE}${item.product_details.thumnail}`
                              : `${process.env.REACT_APP_API_IMAGE_SECOND}${item.product_details.thumnail}`
                          }
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                    <div className="pro_name">
                      <p>{item.product_details.name}</p>
                    </div>
                    <div className="pro_price">
                      <p>Rs-{item.product_details.discount}</p>
                    </div>
                  </div>
                </Link>
                <div className="wishcart_button_page">
                  <div
                    onClick={(e) => Add_chart(e, item)}
                    className="Remove_cart_button"
                  >
                    <p>MOVE TO BAG</p>
                  </div>
                  <div
                    onClick={() => remove_chart(item.product_id)}
                    className="Remove_cart_button"
                  >
                    <p>X</p>
                  </div>
                </div>
              </>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Wishlist;
