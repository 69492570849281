import "./footer.css";
import Img1 from "./6.png";
import Img2 from "./7.png";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import cards from "./product-payment (5).webp";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_FOOTER;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (formData.email) {
      setLoading(true);

      try {
        // eslint-disable-next-line
        const response = await axios.post(
          `https:${api}`, 
          formData,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        toast.success("Successfully ");
        toast.success("Our team contact you soon....");

        setFormData({
          email: "",
        });
      } catch (error) {
        console.error("Error posting data:", error);
        toast.error("Failed to send the message. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Please fill in all fields");
    }
  };
  return (
    <>
      <div className="footer_part1">
        <div className="part1_delivery">
          <div className="img_pos">
            <div className="part1_delivery_img">
              <img src={Img2} alt="" width="100%" height="100%" />
            </div>
          </div>

          <div>
            <p className="p1">Free & Fast Shipping</p>
            <p className="p2">Get Super Fast Delivery Across India</p>
          </div>
        </div>
        <div className="part1_payment">
          <div className="img_pos2">
            <div className="part1_delivery_img">
              <img src={Img1} alt="" width="100%" />
            </div>
          </div>
          <div>
            <p className="p1">Flexible Payment</p>
            <p className="p2">Both Offline & Online Payments are accepted</p>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer">
          <div className="footer_part2">
            <div className="footer_part2_p">
              <p>
                RCI Chikan combines elegance and comfort in signature styles,
                preserving the timeless essence of chikankari. Our luxurious
                fabrics offer a soft and comfortable experience. Each unique
                product tells its story with delicate, beautiful details.
              </p>
            </div>
            <div className="footer_part2_Social">
              <div
                className="social_icon"
                data-tooltip-id="my-facebook"
                data-tooltip-content="Facebook"
              >
                <p>
                  <FaFacebookF />
                </p>
              </div>
              <Tooltip id="my-facebook" />

              <div
                className="social_icon"
                data-tooltip-id="my-instagram"
                data-tooltip-content="Instagram"
              >
                <a
                  href="https://www.instagram.com/rci_chikankari?igsh=ejR3djkxanN1djh6&utm_source=qr"
                  target="__blank"
                >
                  <p>
                    <FaInstagram />
                  </p>
                </a>
              </div>
              <Tooltip id="my-instagram" />

              <div
                className="social_icon"
                data-tooltip-id="my-twitter"
                data-tooltip-content="Twitter"
              >
                <p>
                  <FaXTwitter />
                </p>
              </div>
              <Tooltip id="my-twitter" />
            </div>
          </div>
          <div className="footer_part2_list full-width">
            <div className="footer_part2_container">
              <div className="footer_part2_list_head">
                <p>Quick Links</p>
              </div>
              <ul className="footer_part2_ul">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/product">Product</Link>
                </li>
                <li>
                  <Link to="/wholesale">Wholesaler Enquiry</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="footer_part2__contianer">
              <div className="footer_part2_list_head">
                <p>Customer Service</p>
              </div>
              <ul className="footer_part2_ul">
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/termandcondition">Term & Condition</Link>
                </li>
                <li>
                  <Link to="/shipping">Shipping Policy</Link>
                </li>
                <li>
                  <Link to="/return">Return & Refunds</Link>
                </li>
                <li>
                  <Link to="/howtoorder">How to Order</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_part2">
            <div className="footer_part2_head">
              <p>Sign up to Newsletter</p>
            </div>
            <div className="footer_part2_last_p">
              <p>
                Enter your email address to get 10% off your first order and
                Free shipping.
              </p>
            </div>
            <div className="footer_part2_input_container">
              <div className="footer_part2_input">
                <input
                  type="text"
                  placeholder="Enter the email..."
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="footer_part2_input_button" onClick={handleSubmit}>
                <p>{loading ? "Sending..." : "Send"}</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="hr_line" />
        <div className="footer_copyright">
          <div className="footer_copyright_text">
            <p>&#169; 2024 RCI. All Rights Reserved</p>
          </div>
          <div className="footer_copyright_card">
            <img src={cards} alt="" width="100%" />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
