import { useEffect } from 'react';
import img from './Size Chart 1.jpg'
import './sizechart.css'
// import Box from '@mui/material/Box';
import { IoMdClose } from "react-icons/io";
// import TextField from '@mui/material/TextField';
const Sizechart = ({ closeViewSize }) => {
    
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // overflow hidden lagane ke liye
        return () => {
            document.body.style.overflow = 'auto'; // Component unmount hone par overflow wapas se set karein
        };
    }, []);
    return (
        <>
            <div className='view_parent'></div>

            <div className='search_area_container'>
                <div className='search_area_sub_container'>
                    <div className='size_container'>
                        <div className='size_container_chart'>
                          <img src={img} alt="" width='100%'/>
                        </div>
                        <div onClick={closeViewSize} className='search_close_button'><IoMdClose /></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Sizechart