import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Loading from '../Component/loading/Loading';
const UserContext = createContext();
const ProductsContext = createContext();
const CartContext = createContext();
const ProfileContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function useProducts() {
  return useContext(ProductsContext);
}

// 2. Custom Hook Banayein
export function useCart() {
  return useContext(CartContext);
}

export function useProfile() {
  return useContext(ProfileContext);
}
// 3. Provider Component Banayein

export function UserProvider({ children }) {

  const [user, setUser] = useState(null);

  const [initializing, setInitializing] = useState(true);
  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };
  const initializeUser = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    setInitializing(false);
  };

  if (initializing) {
    initializeUser();
  }

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {initializing ? (
        <><Loading/></>
      ) : (
        children
      )}

    </UserContext.Provider>
  );
}

export function ProductsProvider({ children }) {
  const [products, setProducts] = useState([]);

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api =  process.env.REACT_APP_API_USER_A;

  const product_views = () => {
    axios.get(`https:${api}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  };

  useEffect(() => {
    product_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Aur baki product management functions yahan add karein

  return (
    <ProductsContext.Provider value={{ products, setProducts }}>
      {children}
    </ProductsContext.Provider>
  );
}


export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);
  const { user } = useUser();
  const cart_views = () => {
    const secret = process.env.REACT_APP_SECRET;
    const payload = {
      iss: process.env.REACT_APP_SECRET_ISS,
      aud: process.env.REACT_APP_SECRET_AUD,
    };
    const header = {
      alg: process.env.REACT_APP_SECRET_ALG,
    };
    const encodeHeader = btoa(JSON.stringify(header));
    const payloadString = btoa(JSON.stringify(payload));
    const signature = CryptoJS.HmacSHA256(
      encodeHeader + "." + payloadString,
      secret
    ).toString(CryptoJS.enc.Base64);
    const jwt = `${encodeHeader}.${payloadString}.${signature}`;
    const api =  process.env.REACT_APP_API_USER_B;

    if (user) {
      axios.get(`https:${api}=${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
      })
        .then((response) => {
          setCart([...cart, response.data]);
        })
        .catch((error) => {
          console.error('Error adding to cart:', error);
        });
    }
  };
  useEffect(() => {
    cart_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CartContext.Provider value={{ cart }}>
      {children}
    </CartContext.Provider>
  );
}

export function ProfileProvider({ children }) {
  const [profile, setProfile] = useState([]);
  const { user } = useUser();
  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const apib =  process.env.REACT_APP_API_USER_C;

  const profile_views = () => {
    if (user) {
      axios.get(`https:${apib}=${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
      })
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => {
          console.error('Error adding to cart:', error);
        });
    }
  };

  useEffect(() => {
    profile_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileContext.Provider value={{ profile }}>
      {children}
    </ProfileContext.Provider>
  );
}
