import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from 'react';
import './App.css';
import Navbar from "./Component/navbar/Navbar";
import Footer from "./Component/footer/Footer";
import Login from "./pages/Auth/Login/Login";
import SignIn from "./pages/Auth/signin/SignIn";
import { ProfileProvider, UserProvider } from './stateManagement/UserContext';
import { ProductsProvider } from './stateManagement/UserContext';
import { CartProvider } from './stateManagement/UserContext';
import PrivateRoute from './Component/route/PrivateRoute.jsx';
import Myaccount from "./pages/dashboard/Myaccount";
import Addtocart from "./pages/addcart/Addtocart";
import AddtoRoute from "./Component/route/AddtoRoute";
import Checkout from "./pages/buy/Checkout";
import Term from "./pages/terms&condition/Term";
import Return from "./pages/terms&condition/Retrun";
import Policy from "./pages/terms&condition/Policy";
import { MyContextProvider } from './stateManagement/UserContextProvider';
import Shipping from "./pages/terms&condition/Shipping.jsx";
import Howtoorder from "./pages/terms&condition/Howtoorder.jsx";
import Wholesale from "./pages/wholesale/Wholesale.jsx";
import Contact from "./pages/contact/Contact.jsx";
import Special from "./pages/special/Special.jsx";
// import { useEffect } from "react";
import WishRoute from "./Component/route/WishRoute.jsx";
import Widhlist from "./pages/wishlist/Widhlist.jsx";
// import { ToastContainer } from "react-toastify";
import { Toaster } from 'react-hot-toast';
import Sales from "./pages/catalog/Sales.jsx";
import Allwhite from "./pages/catalog/Allwhite.jsx";
import Bottom from "./pages/catalog/Bottom.jsx";
import Exclusive from "./pages/catalog/Exclusive.jsx";
import Premium from "./pages/catalog/Premium.jsx";
import Sarees from "./pages/catalog/Sarees.jsx";
import Short from "./pages/catalog/Short.jsx";
import Singlepage from "./pages/catalog/Singlepage.jsx";
import Loading from "./Component/loading/Loading.jsx";
import Kurti from "./pages/catalog/Kurti.jsx";
import Dupatta from "./pages/catalog/Dupatta.jsx";
import Sets from "./pages/catalog/Sets.jsx";

import './pages/product/productsingle.css';
import './pages/home/home.css';
import  './pages/product/product.css';
import './Component/home_component/homecom.css';
import './pages/home/slider/slider.css';
import Reset from "./pages/Auth/Reset/Reset.jsx";


const ProductWomen = lazy(() => import('./pages/product/ProductWomen'));
const Home = lazy(() => import('./pages/home/Home'));
const Product = lazy(() => import('./pages/product/Product'));


function App() {
  return (
    <div>
      {/* <div id="myElement"> */}
      <MyContextProvider>
        <UserProvider>
          <ProductsProvider>
            <CartProvider>
              <ProfileProvider>
                <BrowserRouter>
                  <Suspense fallback={<Loading/>}>
                    <Navbar />
                    <Routes>
                      <Route path="/" element={< Home />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/signin' element={<SignIn />} />
                      <Route path='/reset' element={<Reset />} />
                      <Route path='/product' element={<Product />} />

                      <Route path="/product/:productId" element={<ProductWomen />} />

                      <Route path="/Auth" element={<PrivateRoute />}>
                        <Route path="account" element={<Myaccount />} />
                      </Route>
                      <Route path="/addtocart" element={<AddtoRoute />}>
                        <Route path="specialproduct" element={<Addtocart />} />
                      </Route>

                      <Route path="/wishtocart" element={<WishRoute />}>
                        <Route path="wishproduct" element={<Widhlist />} />
                      </Route>
                      <Route path='/buy/:productId' element={<Checkout />} />
                      <Route path='/termandcondition' element={<Term />} />
                      <Route path='/return' element={<Return />} />
                      <Route path='/policy' element={<Policy />} />
                      <Route path='/howtoorder' element={<Howtoorder />} />
                      <Route path='/shipping' element={<Shipping />} />
                      <Route path='/wholesale' element={<Wholesale />} />
                      <Route path='/contact' element={<Contact />} />
                      <Route path='/special' element={<Special />} />
                      <Route path='/sales' element={<Sales />} />
                      <Route path='/allwhite' element={<Allwhite/>} />
                      <Route path='/bottom' element={<Bottom/>} />
                      <Route path='/exclusive' element={<Exclusive/>} />
                      <Route path='/premium' element={<Premium/>} />
                      <Route path='/sarees' element={<Sarees/>} />
                      <Route path='/short' element={<Short/>} />
                      <Route path='/kurti' element={<Kurti/>} />
                      <Route path='/dupatta' element={<Dupatta/>} />
                      <Route path='/sets' element={<Sets/>} />

                      <Route path="/productsingle/:productId" element={<Singlepage />} />
                    </Routes>
                    <Footer />
                  </Suspense>
                </BrowserRouter>
              </ProfileProvider>
            </CartProvider>
          </ProductsProvider>
        </UserProvider>
      </MyContextProvider>
      <Toaster/>
    </div >
  );
}

export default App;
