import './special.css'
import banner from './sale banner.png';
const Special = () => {
    return (
        <>
            <div>
                <img src={banner} alt="" width='100%'/>
            </div>
            <div className="specialoffer_parent">
                <div className="specialoffer_container">
                    <div className="specialoffer_details">
                        <div className="specialoffer_details_head"><p>Special Offers</p></div>
                        <div className="specialoffer_details_para"><p>Another chance to enjoy big savings</p></div>
                    </div>
                    <div className="specialoffer_container_box">
                        <div className="specialoffer_container_box1">
                            <div className="specialoffer_button"><p>Sale</p></div>
                            <div className="specialoffer_box_head"><p>Sale 10% Off</p></div>
                            <div className='specialoffer_box_para'><p>Use this Code to avail 10% discount on order amount</p></div>
                        </div>
                        <div className="specialoffer_container_box2">
                            <div className='specialoffer_box2_para'><p>USE CODE:</p></div>
                            <div className='specialoffer_box2_coupon'><p>NEWMEMBER10</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Special