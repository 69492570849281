import React, { useState } from "react";
import axios from "axios";
import { Box, TextField, Button, Stack, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './wholesale.css';
import CryptoJS from "crypto-js";
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

const Wholesale = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        appointmentDate: ""
    });

    const [loading, setLoading] = useState(false);

    const secret = process.env.REACT_APP_SECRET;
    const payload = {
        iss: process.env.REACT_APP_SECRET_ISS,
        aud: process.env.REACT_APP_SECRET_AUD,
    };
    const header = {
        alg: process.env.REACT_APP_SECRET_ALG,
    };
    const encodeHeader = btoa(JSON.stringify(header));
    const payloadString = btoa(JSON.stringify(payload));
    const signature = CryptoJS.HmacSHA256(
        encodeHeader + "." + payloadString,
        secret
    ).toString(CryptoJS.enc.Base64);
    const jwt = `${encodeHeader}.${payloadString}.${signature}`;
    const api = process.env.REACT_APP_API_WHOLESALEER;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (formData.firstName && formData.lastName && formData.phone && formData.email && formData.appointmentDate) {
            setLoading(true);

            try {
                const response = await axios.post(
                    `https:${api}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                );
                if (response.data.message === "Email sent successfully.") {
                    toast.success('Successfully Send your enquiry ');
                    toast.success('Our team contact you soon....');

                }

                setFormData({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    appointmentDate: ""
                });


            } catch (error) {
                console.error("Error posting data:", error);
                toast.error("Failed to send the message. Please try again.");
            } finally {
                setLoading(false);
            }
        } else {
            toast.warn("Please fill in all fields");
        }
    };

    return (
        <>
            <Helmet>
                <title>Become a Wholesaler - RCI Chikankari Wholesale Program</title>
                <meta name="description" content="Join the RCI Chikankari wholesale program and offer our premium handmade Chikankari kurtis to your customers. Fill out the form to schedule an appointment and start your wholesale journey with us." />
                <meta name="keywords" content="RCI Chikankari wholesaler, wholesale Chikankari kurtis, bulk kurtis, women kurtis wholesale, Chikankari wholesale supplier, wholesale appointment" />
                <meta property="og:title" content="Become a Wholesaler - RCI Chikankari Wholesale Program" />
                <meta property="og:description" content="Interested in selling premium handmade Chikankari kurtis? Join our wholesale program. Schedule an appointment today!" />
                <meta property="og:url" content="https://rcichikankari.com/wholesale" />
                <link rel="canonical" href="https://rcichikankari.com/wholesale" />
            </Helmet>
            <div className='wholesalepage_conatiner'>
                <div className='wholesalepage_part1'>
                    <div className='wholesalepage_head_container'>
                        <div className='wholesalepage_head'>
                            <p>WholeSaler Enquiry</p>
                        </div>
                        <div className='wholesalepage_subhead'>
                            <p>Home / WholeSaler Enquiry</p>
                        </div>
                    </div>
                </div>
                <div className='wholesalepage_part2'>
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '47%' },
                            }}
                            className='box_wholesale_width'
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Box>
                    </div>
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '47%' },
                            }}
                            className='box_wholesale_width'
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-basic"
                                label="Phone"
                                variant="outlined"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Box>
                    </div>
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '96%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-basic"
                                label="Appointment Date"
                                variant="outlined"
                                name="appointmentDate"
                                value={formData.appointmentDate}
                                onChange={handleChange}
                            />
                        </Box>
                    </div>
                    <div className='wholesalepage_button'>
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="contained"
                                endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? "Sending..." : "Send"}
                            </Button>
                        </Stack>
                    </div>
                </div>
                <div className='wholesale_space'></div>
            </div>
        </>
    );
};

export default Wholesale;