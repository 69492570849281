import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { CgShoppingBag } from 'react-icons/cg'
import { BiUserCircle } from 'react-icons/bi';
import './navbar.css';
import React, { useEffect, useState } from 'react';
import { useCart } from '../../stateManagement/UserContext';
import ImgLogo from '../logorci.png';
import Search from '../search/Search';
const Navbar = () => {
  const { cart } = useCart();
  const [search, setSearch] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  let totalcart
  if (cart[0]) {
    totalcart = cart[0].length;
  }
  else {
    totalcart = 0;
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };



    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const closeView = () => {
    setSearch(false);
    document.body.style.overflow = 'auto';
  };

  const [open, setOpen] = useState(false);
  const [block, setBlock] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);


  const handleClick = () => {
    setMenuOpen(!menuOpen);
    setOpen(!open);
    setBlock(!block);
    if (!block) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  };

  const navbarClass = isSticky ? 'navbar sticky' : 'navbar';
  return (
    <>
      <div className="coupon_navbar">
        <p>FLAT 10% OFF ON YOUR FIRST ORDER USE CODE- NEWMEMBER10</p>
      </div>
      <div className={navbarClass}>
        <div className='logo_icon'><Link to='/' className='li_item'><img src={ImgLogo} alt="" width='100%' /></Link></div>
        <div className='item'>
          <ul className='ul_item'>
            <Link to='/' className='li_item'><li>Home</li></Link>
            <Link to='/product' className='li_item'><li>Collection</li></Link>
            <Link to='/wholesale' className='li_item'><li>Wholeseller's Corner</li></Link>
            <Link to='/special' className='li_item'><li>Super Sale</li></Link>
            <Link to='/contact' className='li_item'><li>Contact Us</li></Link>
          </ul>
        </div>
        <div className='icons_div'>
          <ul className='icon_ul'>
            <Link className='li_item' onClick={() => setSearch(true)}><li><AiOutlineSearch /></li></Link>
            <Link to='/wishtocart/wishproduct' className='li_item'><li><BsBookmark /></li></Link>
            <Link to='/addtocart/specialproduct' className='li_item'><li><CgShoppingBag /></li></Link>
            <Link to='/Auth/account' className='li_item'><li><BiUserCircle /></li></Link>
            <div className={`menu-btn  ${menuOpen ? 'open' : ''}`} onClick={handleClick}>
              <div class="menu-btn__burger li_navbar_white_bg"></div>
            </div>
          </ul>
        </div>
      </div>
      {/* menu mobile and tablet */}
      <div>
        {
          open ?
            <div className='handle'>
              <div className={`navbar_item ${!open ? 'opens' : ''}`}>
                <div className='blur'>
                </div>
                <div className='items_pages'>
                  <div className='item_navbar_container_page'>
                    {/* <li className='li_navbar'>
                      <BsBookmark />
                    </li>
                    <li className='li_navbar'>
                      <Link ><CgShoppingBag /></Link>
                    </li>
                    <li className='li_navbar'>
                      <Link ><BiUserCircle /></Link>
                    </li> */}
                    <div className={`menu-btn ${menuOpen ? 'open' : ''}`} onClick={handleClick}>
                      <div class="menu-btn__burger"></div>
                    </div>
                  </div>
                  <ul className='item_menu_ul'>
                  <Link to='/'><li className='item_li' onClick={handleClick}>Home</li></Link>
                  <Link to='/product'><li className='item_li' onClick={handleClick}> Collection</li></Link>
                  <Link to='/special'><li className='item_li' onClick={handleClick}>Super Sale</li></Link>
                    {/* <li className='item_li' onClick={handleClick}><Link to='/'>PRODUCT</Link></li> */}
                    <Link to='/wholesale'><li className='item_li' onClick={handleClick}>Wholeseller's Corner</li></Link>
                    <Link to='/contact'> <li className='item_li' onClick={handleClick}>Contact Us</li></Link>
                  </ul>
                </div>
              </div>
            </div>
            :
            ''
        }
      </div>
      <div>
        {
          search && <Search closeView={closeView} />
        }
      </div>
    </>
  )
}

export default Navbar