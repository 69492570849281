import React, { useState, useRef } from 'react';
import axios from 'axios';
import '../auth.css';
import { useUser } from '../../../stateManagement/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import toast from 'react-hot-toast';
const Login = () => {
  const form = useRef();
  const [data, setData] = useState({});
  const { login } = useUser();
  // eslint-disable-next-line
  const [userdata, setuserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const secret = 'youraregood';

  const payload = {
    iss: "ecommerce",
    aud: "yourname"
  };

  const header = {
    "alg": 'HS256',
  }
  const submithandle = (event) => {
    event.preventDefault();
    setLoading(true);
    const encodeHeader = btoa(JSON.stringify(header));
    const payloadString = btoa(JSON.stringify(payload));
    const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secret).toString(CryptoJS.enc.Base64);
    const jwt = `${encodeHeader}.${payloadString}.${signature}`;
    axios.post('https://admin.rcichikankari.com/customer/Login.php', data, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      },
    }).then((res) => {
      if (res.data === 'fail' || res.data.status ==='User not found') {
        setLoading(false);
        toast.error("incorrect login");
      }
      else {
        login(res.data);
        setuserData(res.data);
        setLoading(false);
        toast.success("successfully Login");
        navigate('/');
      }

    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      <div className='auth_conatiner'>
        <div></div>
        <div className='login'>
          <div className='login_part2'>
            <form ref={form} onSubmit={submithandle} className='login_form'>
              <div className='login_head'><p>Login</p></div>
              <div className='login_input'>
                <input type="email" placeholder="Enter Email " onChange={(e) => setData({ ...data, email: e.target.value })} />
                <input type="password" placeholder="Enter Correct Password" minlength="8" maxlength="128" onChange={(e) => setData({ ...data, password: e.target.value })} pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).*$" />
              </div>
              <div  className='link_signin'><Link to='/reset'><p>Forget Password ?</p></Link></div>
              <div className='part2_button'> <button type='submit' className='login_button' disabled={loading}> {loading ? 'Loading...' : 'Submit'}</button>
              </div>
            </form>
            <hr />
            <div className='link_signin'><Link to='/signin'>Create Your Account</Link></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login


